.product-heading {
    line-height: 60px;
    margin-bottom: 5px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.product-description {
    line-height: 1.6;
    font-weight: 300;
    color: #fff;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media only screen and (min-width: 0px) {
    .product-heading {
        font-size: 30px;
    }

    .product-description {
        font-size: 18px;
        max-width: 500px;
    }

    .right-product-container,
    .left-product-container {
        padding: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-product-container img,
    .left-product-container img {
        min-width: 180px;
        max-width: 260px;
    }
}

@media only screen and (min-width: 576px) {

    .right-product-container img,
    .left-product-container img {
        max-width: 290px;
    }
}

@media only screen and (min-width: 768px) {
    .product-container {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-description {
        max-width: 300px;
    }

    .right-product-container,
    .left-product-container {
        width: 50%;
        margin: 0 auto;
    }

    .right-product-container img,
    .left-product-container img {
        max-width: 320px;
    }
}

@media only screen and (min-width: 1200px) {
    .product-description {
        max-width: 500px;
    }

    .right-product-container img,
    .left-product-container img {
        max-width: 350px;
    }
}