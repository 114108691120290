.fixed-top-bar {
    padding-top: 61px;
}

.product-intro {
    background-color: #eb4511;
    background-image: linear-gradient(315deg, #eb4511 0%, #b02e0c 74%);
}

.day-book {
    background-color: #0cbaba;
    background-image: linear-gradient(315deg, #0cbaba 0%, #380036 74%);

}

.shop {
    background-color: #3f0d12;
    background-image: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);
}

.farm {
    background-color: #63d471;
    background-image: linear-gradient(315deg, #63d471 0%, #233329 74%);
}

p,
li {
    font-size: 1.01rem;
}

.email-alert-close {
    float: right;
    cursor: pointer;
    font-size: 25px;
    font-weight: 600;
}

.footer-address p {
    color: #fff;
    font-size: 1rem;
}

@media only screen and (min-width: 0px) {
    .fixed-top-bar {
        padding-top: 221px;
    }
}

@media only screen and (min-width: 480px) {
    .fixed-top-bar {
        padding-top: 123px;
    }
}

@media only screen and (min-width: 768px) {
    .fixed-top-bar {
        padding-top: 61px;
    }
}