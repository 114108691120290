.fade-pop-up {
    animation: fade-pop-up 1s linear 1 alternate;
}

.fade-slide-right {
    animation: fade-slide-right 1s linear 1 alternate;
}

.fade-slide-left {
    animation: fade-slide-left 1s linear 1 alternate;
}

@keyframes fade-pop-up {
    0% {
        margin-top: 100px;
        opacity: 0;
    }

    100% {
        margin-top: 0px;
        opacity: 1;
    }
}

@keyframes fade-slide-right {
    0% {
        margin-right: 100px;
        opacity: 0;
    }

    100% {
        margin-right: 0px;
        opacity: 1;
    }
}

@keyframes fade-slide-left {
    0% {
        margin-left: 100px;
        opacity: 0;
    }

    100% {
        margin-left: 0px;
        opacity: 1;
    }
}