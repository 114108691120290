.service-title {
    line-height: 1.2;
    font-weight: 300;
    font-size: 30px;
    margin-bottom: 30px;
    color: #121212;
    text-transform: capitalize;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.right-service {
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-img-loader {
    width: 300px;
    height: 300px;
    background-color: #eee;
    border-radius: .2rem;
}

@media only screen and (min-width: 0px) {

    .right-service img,
    .left-service img {
        min-width: 180px;
        max-width: 260px;
    }

    .service-title {
        text-align: center;
    }
}

@media only screen and (min-width: 576px) {

    .right-service img,
    .left-service img {
        max-width: 290px;
    }
}

@media only screen and (min-width: 768px) {
    .service-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .service-title {
        max-width: 300px;
    }

    .right-service,
    .left-service {
        width: 50%;
        margin: 0 auto;
    }

    .right-service img,
    .left-service img {
        max-width: 320px;
    }

    .service-title {
        text-align: left;
    }
}

@media only screen and (min-width: 1200px) {
    .service-title {
        max-width: 500px;
    }

    .right-service img,
    .left-service img {
        max-width: 350px;
    }
}